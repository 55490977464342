:root {
  --main-blue: #3740ff;
  --main-blue-dark: #575fff;
  --main-background: #ffffff;
  --main-black: #181b31;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: local('Roboto'), url(./fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: local('Inter'), url(./fonts/Inter-Bold.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: 'Roboto';
  background-color: var(--main-background);
  transition: all 0.3s ease;
  color: var(--main-black);
}

body.dark {
  background-color: var(--main-black);
  color: #fff;
  transition: all 0.3s ease;
}

body.dark a {
  color: #fff;
}

body .header-wrapper-logo.white {
  display: none;
}

body.dark .header-wrapper-logo {
  display: none;
}

body.dark .header-wrapper-logo.white {
  display: inline-block;
}

body.dark .header-right-theme img {
  filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(323deg)
    brightness(101%) contrast(102%);
}

body.dark .work-entry > ul > li:before {
  background-color: #fff;
}

.header {
  margin-top: 45px;
}

.container {
  max-width: 1430px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.header > .container > .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 170px;
}

.header > .container > .header-wrapper a > img {
  width: 165px;
}

.header > .container > .header-wrapper > .header-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-right > .header-right-menu {
  display: none;
  cursor: pointer;
  padding: 5px;
}

.menu-overlay {
  display: none;
}

.header-nav {
  margin-right: auto;
  margin-left: 44px;
}

.header-nav a {
  font-size: 16px;
  text-decoration: none;
  padding: 10px 16px;
  transition: all 0.3s ease;
}

.header-nav a:hover {
  transition: all 0.3s ease;
}

.header-nav a:active {
  font-weight: 700;
}

.header-right-theme > img {
  max-width: 20px;
  margin-right: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.header-right-theme > img:hover {
  transform: scale(1.2) rotate(-20deg);
  transition: all 0.3s ease;
}

.header-right-language {
  /* display: flex; */
  display: none;
  cursor: pointer;
  margin-right: 20px;
  /* transition: all 0.3s ease; */
}

.header-right-language:hover {
  font-weight: 700;
  transition: all 0.3s ease;
}

.header-right-language > .header-right-language-english {
  display: none;
}

.header-right-contact {
  background-color: var(--main-blue);
  color: #fff;
  padding: 12px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.875em;
  transition: all 0.3s ease;
  border: none;
  line-height: 1em;
}

.header-right-contact:hover {
  background-color: var(--main-blue-dark);
  transition: all 0.3s ease;
}

.hero-text h1 {
  font-size: 3.5em;
  line-height: 1.4em;
  font-family: 'Inter';
  font-weight: 700;
}



.hero-text {
  position: relative;
}

.about-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 120px;
}

.about-left {
  margin-right: 80px;
}

.about-left img {
  max-height: 550px;
}

.about-right-title {
  font-size: 2.4em;
  line-height: 1em;
  margin-bottom: 12px;
  font-weight: 900;
}

.about-right-text {
  font-size: 1.3em;
  line-height: 1.3em;
  margin-bottom: 20px;
}

.about-right-subtitle {
  font-weight: 900;
  font-size: 1.3em;
  line-height: 1em;
  margin-bottom: 10px;
}

.about-social {
  display: flex;
}

.about-social a img {
  width: 30px;
  margin-right: 15px;
}

.work {
  margin-top: 95px;
  margin-bottom: 95px;
}

.work-title {
  font-family: Inter;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: 30px;
  font-size: 3.5em;
}

.work-entry {
  margin-bottom: 30px;
}

.work-entry-title {
  font-size: 1.563em;
  line-height: 1em;
  margin-bottom: 5px;
}

.work-entry-subtitle {
  font-size: 0.875em;
  margin-bottom: 10px;
}

.work-entry > ul > li {
  list-style: none;
  display: flex;
  align-items: center;
}

.work-entry > ul > li:before {
  content: '';
  width: 5px;
  height: 5px;
  background-color: var(--main-blue);
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  margin-right: 10px;
}

.projects {
  margin-bottom: 100px;
}

.projects-title {
  font-family: Inter;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: 20px;
  font-size: 3.5em;
}

.projects-text {
  font-size: 1.3em;
  max-width: 700px;
  margin-bottom: 40px;
}

.projects-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  height: fit-content;
}

.project-entry {
  position: relative;
  overflow: hidden;
  display: none;
  transition: all 0.3s ease;
}

.project-entry.visible {
  display: block;
}

.project-entry:hover {
  transform: scale(0.99);
  transition: all 0.3s ease;
}

.project-entry-title {
  /* display: none; */
  position: absolute;
  top: 60px;
  left: 50px;
  font-size: 1.875em;
  font-weight: 900;
  z-index: 2;
  line-height: 1em;
  color: var(--main-black);
}

.project-entry img {
  max-width: 100%;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.project-bottom {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.project-load {
  background-color: var(--main-blue);
  color: #fff;
  padding: 12px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.875em;
  transition: all 0.3s ease;
  line-height: 1em;
  border: none;
  cursor: pointer;
}

.project-load:hover {
  background-color: var(--main-blue-dark);
  transition: all 0.3s ease;
}

.skills {
  margin-top: 100px;
  margin-bottom: 170px;
}

.skills-title {
  font-family: Inter;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: 30px;
  font-size: 3.5em;
}

.skills-text {
  font-size: 1.3em;
  max-width: 700px;
  margin-bottom: 40px;
}

.skills-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 40px;
}

.skills-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.skills-item img {
  max-width: 50px;
  max-height: 50px;
  margin-right: 20px;
}
.skills-item p {
  font-size: 1.2em;
}

.contact {
  margin-top: 100px;
  margin-bottom: 170px;
}

.contact-title {
  font-family: Inter;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: 30px;
  font-size: 3.5em;
}

.contact-text {
  font-size: 1.5em;
  max-width: 700px;
  margin-bottom: 40px;
  line-height: 1.5em;
}

.contact-form form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  max-width: 80%;
}

.contact-form-entry {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 30px;
  padding: 0 15px;
}

.contact-form-entry label {
  font-size: 0.875em;
  line-height: 1em;
  margin-bottom: 5px;
}

.contact-form-entry input,
.contact-form-entry textarea {
  padding: 20px 10px;
  line-height: 1em;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  resize: none;
  background-color: rgb(238, 238, 238);
}

.contact-form-entry:nth-last-child(3) {
  width: 100%;
}

.contact-form-entry-check,
.contact-form-entry-submit {
  width: 100%;
  margin-bottom: 30px;
  padding: 0 15px;
}

.contact-form-entry-check input {
  margin-right: 15px;
}

.contact-form-entry-submit input {
  background-color: var(--main-blue);
  color: #fff;
  padding: 12px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.875em;
  transition: all 0.3s ease;
  line-height: 1em;
  border: none;
  cursor: pointer;
}

.contact-form-entry-submit input:hover {
  background-color: var(--main-blue-dark);
  transition: all 0.3s ease;
}

footer.footer {
  background-color: var(--main-black);
  padding: 60px 0;
  border-top: 1px solid #fff;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.footer-top img {
  max-width: 175px;
}

.footer-nav a {
  color: #fff;
  text-decoration: none;
  margin-left: 50px;
  transition: all 0.3s ease;
}
.footer-nav a:hover {
  text-decoration: underline;
  transition: all 0.3s ease;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-info {
  width: 100%;
}

.footer-info-mail,
.footer-info-phone {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.footer-info-mail {
  margin-bottom: 10px;
}

.footer-info-mail a,
.footer-info-phone a {
  color: #fff;
  text-decoration: none;
}

.footer-info-mail a:hover,
.footer-info-phone a:hover {
  text-decoration: underline;
  transition: all 0.3s ease;
}

.footer-info-mail img,
.footer-info-phone img {
  max-width: 20px;
  margin-right: 10px;
}

.footer-social {
  display: flex;
}

.footer-social a img {
  width: 30px;
  margin-left: 15px;
}
.content-heading {
  font-size: 3.5em;
  line-height: 1.4em;
  font-family: 'Inter';
  font-weight: 700;
}

.content {
  margin-bottom: 100px;
  margin-top: 100px;
}

.content p,
.content ul,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6,
.w-embed {
  margin-bottom: 10px;
}

.content h1 {
  margin-bottom: 30px;
}

@media (max-width: 1500px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1300px) {
  .container {
    max-width: 1000px;
  }

  .header > .container > .header-wrapper {
    margin-bottom: 140px;
  }

  .hero-text h1,
  .work-title,
  .projects-title,
  .skills-title,
  .contact-title,
  .content-heading {
    font-size: 3em;
  }

  .about-left {
    margin-right: 50px;
  }

  .about-left img {
    max-height: 450px;
  }

  .about-right-title {
    font-size: 2em;
  }

  .about-right-text {
    font-size: 1.1em;
  }

  .about-right-subtitle {
    font-size: 1.2em;
  }

  .skills {
    margin-bottom: 140px;
  }

  .projects-text,
  .skills-text,
  .contact-text {
    font-size: 1.1em;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 900px;
  }

  .header > .container > .header-wrapper {
    margin-bottom: 110px;
  }

  .hero-text h1,
  .work-title,
  .projects-title,
  .skills-title,
  .contact-title,
  .content-heading {
    font-size: 2.4em;
  }

  .about-left {
    margin-right: 50px;
  }

  .about-left img {
    max-height: 450px;
  }

  .about-right-title {
    font-size: 1.8em;
  }

  .about-right-text {
    font-size: 1em;
    line-height: 1.3em;
  }

  .about-right-subtitle {
    font-size: 1.1em;
  }

  .work-entry-title {
    font-size: 1.3em;
  }
  .work-entry-subtitle {
    font-size: 0.8em;
  }

  .skills {
    margin-bottom: 140px;
  }

  .projects-text,
  .skills-text,
  .contact-text {
    font-size: 1em;
  }

  .contact-form-entry input,
  .contact-form-entry textarea {
    padding: 12px 10px;
  }

  .contact-form-entry-submit {
    margin-bottom: 0;
  }

  .contact {
    margin-bottom: 120px;
  }

  footer.footer {
    padding: 30px 0;
  }

  .footer-top img {
    max-width: 140px;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 700px;
  }

  .content {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .header > .container > .header-wrapper {
    margin-bottom: 80px;
  }

  .header-nav {
    display: none;
  }

  .header-right-theme img {
    margin-right: 20px;
  }

  body.dark .header-right > .header-right-menu {
    filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(323deg)
      brightness(101%) contrast(102%);
  }

  .header-right > .header-right-menu {
    display: block;
    max-width: 40px;
    margin-left: 20px;
  }

  .menu-overlay {
    width: 100vw;
    height: 100vh;
    background-color: var(--main-black);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
    display: none;
  }

  .menu-overlay-close {
    width: 40px;
    height: 40px;
    background-image: url(./assets/icon_close.svg);
    background-position: center;
    background-size: 30px auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px;
  }

  .menu-overlay nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .menu-overlay nav a {
    color: #fff;
    font-size: 2em;
    font-weight: 900;
    margin: 5px 0;
    text-decoration: none;
    text-align: center;
    padding: 5px;
  }

  .hero-text h1,
  .work-title,
  .projects-title,
  .skills-title,
  .contact-title,
  .content-heading {
    font-size: 2.2em;
  }
  .project-entry-title {
    font-size: 1.4em;
    top: 40px;
    left: 30px;
  }
  .about-wrapper {
    margin-top: 100px;
  }

  .about-left {
    margin-right: 30px;
  }

  .about-right-title {
    font-size: 1.5em;
  }

  .about-right-text {
    font-size: 0.9em;
  }

  .skills-items {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 0 30px;
  }

  .header > .container > .header-wrapper {
    margin-bottom: 80px;
  }

  .header-nav {
    display: none;
  }

  .hero-text h1,
  .work-title,
  .projects-title,
  .skills-title,
  .contact-title,
  .content-heading {
    font-size: 2em;
  }
  .project-entry-title {
    font-size: 1.4em;
  }
  .projects-wrapper {
    grid-template-columns: 1fr;
  }

  .about-wrapper {
    margin-top: 100px;
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .about-left img {
    width: 100%;
    max-height: auto;
  }

  .about-left {
    margin-right: 0;
    margin-top: 30px;
  }

  .about-left img {
    max-height: 500px;
  }

  .about-right-title {
    font-size: 1.5em;
  }

  .about-right-text {
    font-size: 0.9em;
  }

  .skills {
    margin-bottom: 110px;
  }

  .skills-items {
    grid-template-columns: 1fr 1fr;
  }

  .contact-text {
    max-width: 400px;
  }

  .contact {
    margin-bottom: 80px;
  }

  .contact-form {
    margin: 0 -15px;
  }

  .contact-form form {
    max-width: 100%;
    margin: 0;
  }
}

@media (max-width: 565px) {
  .container {
    padding: 0 15px;
  }
  .header {
    margin-top: 25px;
  }

  .header-right-language {
    margin-right: 10px;
  }
  .header-right-theme > img {
    margin-right: 10px;
  }
  .hero-text h1,
  .work-title,
  .projects-title,
  .skills-title,
  .contact-title,
  .content-heading {
    font-size: 1.8em;
  }

  .header-right-contact {
    display: none;
  }

  .header-right > .header-right-menu {
    margin-left: 10px;
    margin-right: 5px;
  }

  .work-entry-title {
    font-size: 1.2em;
  }

  .contact-form-entry {
    width: 100%;
  }

  .contact-form-entry input,
  .contact-form-entry textarea {
    padding: 10px 10px;
  }

  .contact-form {
    margin: 0 -5px;
  }

  .contact-form-entry {
    padding: 0 5px;
    margin-bottom: 20px;
  }
  .contact-form-entry-check, .contact-form-entry-submit {
    padding: 0 5px;
    margin-bottom: 20px;
  }
  .contact-form-entry-submit {
    padding: 0 5px;
    margin-bottom: 20px;
  }
  .skills-items {
    gap: 20px;
  }

  .skills-item img {
    max-width: 30px;
    max-height: 30px;
  }
  .skills-item p {
    font-size: 1em;
  }

  .footer-top {
    flex-direction: column;
  }
  .footer-top .footer-nav {
    margin-top: 30px;
  }
  .footer-nav a {
    margin: 0 15px;
  }

  .footer-bottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-info {
    margin-bottom: 30px;
  }

  .footer-info-mail,
  .footer-info-phone {
    justify-content: center;
  }
}

@media (max-width: 360px) {
  .hero-text h1,
  .work-title,
  .projects-title,
  .skills-title,
  .contact-title,
  .content-heading {
    font-size: 1.5em;
    margin-bottom: 20px;
  }

  .about-wrapper {
    margin-top: 80px;
  }

  .work {
    margin-bottom: 80px;
  }

  .work-entry {
    margin-bottom: 20px;
  }

  .work-entry-title {
    font-size: 1em;
    margin-bottom: 3px;
  }

  .work-entry-subtitle {
    font-size: 0.7em;
  }

  .work-entry > ul > li {
    font-size: 0.8em;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
